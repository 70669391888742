import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { tr } from "date-fns/locale";
import dayjs from "dayjs";

import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckIcon from "@mui/icons-material/Check";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

export const CalendarComponent = (props) => {
	const locales = {
		"tr-Tr": tr,
	};

	const localizer = dateFnsLocalizer({
		format,
		parse,
		startOfWeek,
		getDay,
		locales,
	});

	localizer.formats.yearHeaderFormat = "yyyy";

	const createEventList = (list) => {
		if (list?.length > 0) {
			const eventList = [];
			list.forEach((element, index) => {
				const endDate = new Date(element.meetingDate).setMinutes(new Date(element.meetingDate).getMinutes() + element.duration);
				const title = dayjs(element.meetingDate).format("HH:mm") + " - " + element.fullName;
				const isEnded = new Date() > endDate;

				const tempEvent = {
					start: new Date(element.meetingDate),
					end: new Date(endDate),
					title: isEnded ? (
						<span style={{ backgroundColor: "#2779ff", display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "5px", padding: "3px" }}>
							<CheckIcon style={{ fontSize: "18px", marginInlineEnd: "8px" }} />
							<span style={{ color: "#FFF" }}>{title}</span>
						</span>
					) : element.isStarted ? (
						<span style={{ backgroundColor: "#14B8A6", display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "5px", padding: "3px" }}>
							<PersonOutlineIcon style={{ fontSize: "18px", marginInlineEnd: "8px" }} />
							<span style={{ color: "#FFF", fontWeight: "bold" }}>{title}</span>
						</span>
					) : (
						<span style={{ backgroundColor: "#ff6c33", display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "5px", padding: "3px" }}>
							<HourglassTopIcon style={{ fontSize: "18px", marginInlineEnd: "8px" }} />
							<span style={{ color: "#FFF" }}>{title}</span>
						</span>
					),
					fullName: element.fullName,
					hexColor: "#FFF",
					id: index,
					allDay: false,
				};
				eventList.push(tempEvent);
			});
			return eventList;
		}
	};

	return (
		<Calendar
			culture={"tr-Tr"}
			localizer={localizer}
			events={createEventList(props.list)}
			startAccessor="start"
			endAccessor="end"
			defaultView={Views.MONTH}
			style={{ minHeight: "90vh", minWidth: "100%" }}
			views={["month", "day", "agenda"]}
			showAllEvents={false}
			messages={{
				agenda: "Ajanda",
				allDay: "Tüm Günler",
				year: "Yıl",
				month: "Ay",
				week: "Hafta",
				day: "Gün",
				today: "Bugün",
				previous: "Önceki",
				next: "Sonraki",
				date: "Gün",
				noEventsInRange: "Seçtiğiniz aralıkta herhangi bir etkinlik yok",
				time: "Zaman",
				tomorrow: "Yarın",
				work_week: "Çalışma Haftası",
				yesterday: "Dün",
				event: "Etkinlik",
				showMore: function showMore(total) {
					return (
						<span
							style={{
								fontSize: "12px",
								marginLeft: "10px",
								color: "#000",
							}}
						>
							{total} tane daha
						</span>
					);
				},
			}}
			eventPropGetter={(event, start, end, isSelected, label) => {
				let newStyle = {
					backgroundColor: event.hexColor,
					color: "black",
				};

				return {
					className: "",
					style: newStyle,
				};
			}}
			tooltipAccessor={(event) => dayjs(event.start).format("DD.MM.YYYY HH:mm") + " tarihinde " + event.fullName + " görüşmesi"}
		/>
	);
};
