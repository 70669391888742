import React, { Component } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ErrorIcon from "@mui/icons-material/Error";

import { observer, inject } from "mobx-react";
import { VideoUrl } from "../../model/VideoUrl";
import getVideoUrl from "../../utils/api/Doctor/Appointment/GetVideoUrl";

class VideoExternalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoUrl: VideoUrl.create({}),
      videoLink: "",
      ready: false,
      name: "",
      showAgreement: false
    };
  }

  componentDidMount = async () => {
    this.props.store.setLoadingBarStatus(true);

    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
    });

    if (this.props.store.baseUrl && this.props.match.params.url) {
      let urlResponse = await getVideoUrl(
        this.props.store.baseUrl,
        this.props.match.params.url
      );
      if (urlResponse.data !== null && urlResponse.data !== undefined) {
        if (urlResponse.status === 200) {
          this.setState({
            videoUrl: urlResponse.data,
          });
          this.setVideoUrl(urlResponse.data);
        }
      }
    }
  };

  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  startCall = () => {
    this.handleShowAgreement();
    this.setVideoUrl(this.state.videoUrl);
    this.setState({
      ready: true,
    });
  };

  setVideoUrl = (model) => {
    const name = this.state.name?.length > 0 ? this.state.name : "Katılımcı";
    const doctorName = name.replace(" ", "%20");
    const params = new URLSearchParams();
    params.append("jitsi_meet_external_api_id", "0");
    params.append("config.toolbarButtons", '["microphone","camera"]');
    params.append("config.hideConferenceSubject", "true");
    params.append("config.hideConferenceTimer", "true");
    params.append("config.connectionIndicators", '{"disabled":true}');
    params.append("interfaceConfig.VIDEO_QUALITY_LABEL_DISABLED", "true");
    params.append("userInfo.displayName", '"doctorName"');
    params.append("appData.localStorageContent", "null");

    const urlParameterString = params
      .toString()
      .replace("doctorName", doctorName);

    this.setState({
      videoLink: model.realUrl + urlParameterString,
    });
  };

	handleShowAgreement = () => {
		this.setState({
			showAgreement: !this.state.showAgreement,
		});
	};

  render() {
    return this.state.videoLink ? (
      !this.state.ready ? (
        <>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: 1, height: "100vh" }}
          >
            <img
              src="/assets/images/wholemedic-logo.png"
              alt="wholemedics"
              style={{ width: "50%" }}
            />
            <TextField
              variant="outlined"
              type="text"
              value={this.state.name}
              style={{
                width: "50%",
                marginBottom: "10px",
                borderRadius: "10px",
                marginTop: "10px",
              }}
              label="İsim Soyisim"
              onChange={this.onNameChange}
            />
            <Button variant="contained" onClick={this.handleShowAgreement}>
              Randevuya Giriş Yap
            </Button>
          </Stack>
          <Dialog open={this.state.showAgreement}>
            <DialogTitle id="alert-dialog-title">
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Bilgilendirme
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography>
                Değerli kullanıcımız, hizmetin yerine getirilebilmesi için{" "}
                <b>kamera ve mikrofon erişim izni </b>gerekmektedir. Yapacağınız
                görüşmeyi karşı tarafa bildirerek istediğiniz anda sonlandırabilirsiniz.
              </Typography>
              <Typography>
                Karşı taraf ile yapacağınız görüşmede{" "}
                <b>gerektiğinde ses ve görüntü kaydı yapılabilmektedir.</b>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={this.startCall}
              >
                Devam Et
              </Button>
            </DialogActions>
          </Dialog>
				</>
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: 1, height: "100vh" }}
          >
            <iframe
              allow="camera; microphone; display-capture; autoplay; clipboard-write; hid"
              id="external-meeting-iframe"
              src={this.state.videoLink}
              title="Görüşme Ekranı"
              style={{
                height: "calc(100%)",
                width: "calc(100%)",
                border: "0",
              }}
            />
          </Stack>

          <Box
            variant="contained"
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 2000,
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              color="error"
              className="video-call-button-endcall"
              onClick={() =>
                this.setState({
                  pageCloseWarningDisplay: true,
                })
              }
            >
              <CallEndIcon style={{ color: "#fff" }} />
            </IconButton>
            <p
              style={{
                fontSize: "12px",
                marginTop: "5px",
                color: "gray",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Görüşmeyi
              <br />
              Sonlandır
            </p>
          </Box>

          <Dialog
            fullWidth
            maxWidth="xs"
            open={this.state.pageCloseWarningDisplay}
            onClose={this.onClickCloseWarningDisplayMenu}
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent style={{ paddingTop: "0" }}>
              <Grid container>
                <Grid item xs={3}>
                  <ErrorIcon color="error" style={{ fontSize: "80px" }} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    Görüşmeden çıkmak istediğinize emin misiniz ?
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() =>
                  this.setState({
                    pageCloseWarningDisplay: false,
                  })
                }
              >
                GERİ DÖN
              </Button>
              <Button
                color="error"
                variant="contained"
                onClick={() =>
                  this.setState({
                    videoLink: "",
                  })
                }
              >
                GÖRÜŞMEDEN AYRIL
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )
    ) : (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100vh" }}
      >
        <img
          src="/assets/images/wholemedic-logo.png"
          alt="wholemedics"
          style={{ width: "50%" }}
        />
      </Stack>
    );
  }
}

export default inject("store")(observer(VideoExternalPage));
