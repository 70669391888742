import axios from "axios";

export const getMeetingList = async (baseUrl, token, doctorId) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + "/doctor/" + doctorId + "/meetings", config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const getMeeting = async (baseUrl, token, doctorId, id) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + "/doctor/" + doctorId + "/meeting/" + id, config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const meetingCheck = async (baseUrl, token, url) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + "/meeting_check/" + url, config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const upsertMeeting = async (baseUrl, token, doctorId, meeting, participant = false) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	let postResponse = null;

	await axios
		.post(baseUrl + "/doctor/" + doctorId + "/meeting" + (participant ? "/participant" : ""), meeting, config)
		.then((res) => {
			postResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				postResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return postResponse;
};

export const getParticipantList = async (baseUrl, token, doctorId, participantId) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + "/doctor/" + doctorId + "/participants/" + participantId, config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const getAllParticipantList = async (baseUrl, token, doctorId) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + "/doctor/" + doctorId + "/participants/0", config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const insertParticipant = async (baseUrl, participant) => {
	let postResponse = null;

	await axios
		.post(baseUrl + "/doctor/participant", participant)
		.then((res) => {
			postResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				postResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return postResponse;
};

export const startParticipant = async (baseUrl, participantId) => {
	let putResponse = null;

	await axios
		.put(baseUrl + "/doctor/participant/start/" + participantId)
		.then((res) => {
			putResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				putResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return putResponse;
};

export const inviteMeeting = async (baseUrl, token, doctorId, invite) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	let postResponse = null;

	await axios
		.post(baseUrl + "/doctor/" + doctorId + "/meeting-invite", invite, config)
		.then((res) => {
			postResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				postResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return postResponse;
};

export const changeMeetingStatus = async (baseUrl, token, doctorid, meetingId, status) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	let putResponse = null;

	await axios
		.put(baseUrl + "/doctor/" + doctorid + "/meeting/" + meetingId + "/status/" + status, {}, config)
		.then((res) => {
			putResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				putResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return putResponse;
};
