import React, { Component } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography, AppBar, Toolbar, LinearProgress, Paper } from "@mui/material";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ErrorIcon from "@mui/icons-material/Error";

import { observer, inject } from "mobx-react";
import { VideoUrl } from "../../model/VideoUrl";
import { meetingCheck, startParticipant } from "../../utils/api/Dashboard/Meeting";
import { getResponses } from "../../utils/api/Question/Question";
import MapissoAlert from "../../components/common/MapissoAlert";

class VideoMeetingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoUrl: VideoUrl.create({}),
			videoLink: "",
			ready: false,
			name: "",
			allowCamMic: false,
			showAgreement: false,
			participantId: null,
			responses: [],
		};
	}

	componentDidMount = async () => {
		this.props.store.setLoadingBarStatus(true);
		window.addEventListener("beforeunload", (e) => {
			e.preventDefault();
		});

		if (this.props.store.baseUrl && this.props.match.params.guid) {
			let checkMeetingReq = await meetingCheck(this.props.store.baseUrl, this.props.store.token, this.props.match.params.guid);
			if (checkMeetingReq !== undefined && checkMeetingReq !== null) {
				if (checkMeetingReq.status === 200) {
					let responses = await getResponses(this.props.store.baseUrl, checkMeetingReq.data.data.doctorId, checkMeetingReq.data.data.meetingId, checkMeetingReq.data.data.participantId);

					return this.setState({
						meeting: checkMeetingReq.data.data,
						allowCamMic: true,
						ready: true,
						responses: responses.data.data,
						participantId: checkMeetingReq.data.data.participantId,
					});
				}
				this.props.store.setMessage("Görüşme bulunamadı", true, "error");
			}
		} else {
			this.props.store.setMessage("Bir hata oluştu", true, "error");
		}
	};

	startCall = async () => {
		if (!this.state.meeting.startingDate) await startParticipant(this.props.store.baseUrl, this.state.participantId);
		const url = this.state.meeting.videoUrl;
		this.setState({
			videoLink: url,
		});
	};

	handleAgreementDialog = () => {
		if (this.state.allowCamMic) {
			if (this.state.responses?.onams?.length === 0) {
				this.setState({
					allowCamMic: false,
				});
				this.startCall();
			} else {
				this.setState({
					allowCamMic: false,
					showAgreement: true,
				});
			}
		} else {
			this.setState({
				showAgreement: !this.state.showAgreement,
			});
		}
	};

	handleCamMicDialog = () => {
		if (!this.state.allowCamMic) {
			if (this.state.name.trim().length < 2 || this.state.name.trim().split(" ").length < 2) {
				return this.props.store.setMessage("Geçersiz isim", true, "error");
			}
		}
		this.setState({
			allowCamMic: !this.state.allowCamMic,
		});
	};

	replaceOnams = (onamForms) => {
		const a = onamForms?.onams?.map((e) => {
			return { ...e, onamText: e?.onamText?.replaceAll("#İsim Soyisim#", this.state.responses?.name)?.replaceAll("#Telefon Numarası#", this.state.responses?.phoneNumber) };
		});

		onamForms?.questions?.forEach((q) => {
			a.forEach((e) => {
				e.onamText = e?.onamText?.replaceAll("#" + q?.questionText + "#", onamForms?.responses?.find((a) => a.questionId === q.questionId)?.responseValue);
			});
		});
		return a;
	};

	render() {
		return (
			<>
				<MapissoAlert />
				{this.state.ready ? (
					!this.state.videoLink ? (
						<Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: 1, height: "100vh" }}>
							<img src="/assets/images/wholemedic-logo.png" alt="wholemedics" style={{ width: "50%" }} />
							<Dialog open={this.state.showAgreement} maxWidth={"md"} fullWidth>
								<AppBar sx={{ position: "relative", background: "#2196f3" }}>
									<Toolbar>
										<Typography variant="h6" component="div">
											AYDINLATILMIŞ ONAM (HASTA RIZA) FORMU
										</Typography>
									</Toolbar>
								</AppBar>
								<div
									style={{
										position: "absolute",
										height: "5px",
										width: "100%",
										zIndex: "5000",
									}}
								>
									<LinearProgress
										style={{
											display: this.props.store.loadingBarStatus ? "none" : "block",
										}}
										color="primary"
									/>
								</div>
								<DialogContent style={{ padding: "30px" }}>
									<Paper
										elevation={0}
										style={{
											width: "100%",
											minHeight: "100px",
										}}
									>
										<Grid container spacing={3} style={{ width: "100%" }}>
											{this.replaceOnams(this.state.responses)?.map((onam) => (
												<div className="meeting-agreement" style={{ padding: "40px", width: "100%" }}>
													<div className="container" dangerouslySetInnerHTML={{ __html: onam.onamText }}></div>
												</div>
											))}
											{/* <MeetingAgreement
										var={{
											patientName: this.state.participant?.name,
											doctorName: this.state.participant?.doctorName,
										}}
									/> */}
										</Grid>
									</Paper>
								</DialogContent>
								<DialogActions>
									<Button variant="contained" color="primary" onClick={() => this.startCall()} style={{ height: "55px", float: "right" }}>
										Kabul Et
									</Button>
								</DialogActions>
							</Dialog>
							<Dialog open={this.state.allowCamMic} maxWidth={"sm"} fullWidth>
								<DialogTitle id="alert-dialog-title">
									<Typography variant="body1" style={{ fontWeight: "bold" }}>
										Bilgilendirme
									</Typography>
								</DialogTitle>
								<DialogContent>
									<Typography>
										Değerli kullanıcımız, görüşmeyi yapabilmek için <b>kamera ve mikrofon erişim izni </b>
										gerekmektedir. Görüşmeyi karşı tarafa bildirerek istediğiniz anda sonlandırabilirsiniz.
									</Typography>
									<br />
									<Typography>
										Görüşme sırasında <b>gerektiğinde ses ve görüntü kaydı yapılabilmektedir.</b>
									</Typography>
								</DialogContent>
								<DialogActions>
									<Button variant="contained" color="primary" onClick={this.handleAgreementDialog} style={{ height: "55px", float: "right" }}>
										Kabul Et
									</Button>
								</DialogActions>
							</Dialog>
						</Stack>
					) : (
						<>
							<Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: 1, height: "100vh" }}>
								<iframe
									allow="camera; microphone; display-capture; autoplay; clipboard-write; hid"
									id="external-meeting-iframe"
									src={this.state.videoLink}
									title="Görüşme Ekranı"
									style={{
										height: "calc(100%)",
										width: "calc(100%)",
										border: "0",
									}}
								/>
							</Stack>

							<Box
								variant="contained"
								sx={{
									position: "fixed",
									bottom: 0,
									left: 0,
									zIndex: 2000,
									backgroundColor: "white",
									borderRadius: "10px",
									padding: "8px",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<IconButton
									aria-label="upload picture"
									color="error"
									className="video-call-button-endcall"
									onClick={() =>
										this.setState({
											pageCloseWarningDisplay: true,
										})
									}
								>
									<CallEndIcon style={{ color: "#fff" }} />
								</IconButton>
								<p
									style={{
										fontSize: "12px",
										marginTop: "5px",
										color: "gray",
										fontWeight: "bold",
										textAlign: "center",
									}}
								>
									Görüşmeyi
									<br />
									Sonlandır
								</p>
							</Box>

							<Dialog fullWidth maxWidth="xs" open={this.state.pageCloseWarningDisplay} onClose={this.onClickCloseWarningDisplayMenu}>
								<DialogTitle id="alert-dialog-title"></DialogTitle>
								<DialogContent style={{ paddingTop: "0" }}>
									<Grid container>
										<Grid item xs={3}>
											<ErrorIcon color="error" style={{ fontSize: "80px" }} />
										</Grid>
										<Grid item xs={9}>
											<Typography variant="body1" style={{ fontWeight: "bold" }}>
												Görüşmeden çıkmak istediğinize emin misiniz ?
											</Typography>
										</Grid>
									</Grid>
								</DialogContent>
								<DialogActions>
									<Button
										color="primary"
										onClick={() =>
											this.setState({
												pageCloseWarningDisplay: false,
											})
										}
									>
										GERİ DÖN
									</Button>
									<Button
										color="error"
										variant="contained"
										onClick={() =>
											this.setState({
												videoLink: "",
												ready: false,
											})
										}
									>
										GÖRÜŞMEDEN AYRIL
									</Button>
								</DialogActions>
							</Dialog>
						</>
					)
				) : (
					<Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: 1, height: "100vh" }}>
						<img src="/assets/images/wholemedic-logo.png" alt="wholemedics" style={{ width: "50%" }} />
					</Stack>
				)}
			</>
		);
	}
}

export default inject("store")(observer(VideoMeetingPage));
