import { useState } from "react";
import { BtnBold, BtnItalic, BtnBulletList, BtnLink, BtnUnderline, BtnStrikeThrough, Editor, EditorProvider, Toolbar, createDropdown } from "react-simple-wysiwyg";

const EditorComponent = (props) => {
	const [value, setValue] = useState(props.value);
	const onChange = (e) => {
		setValue(e.target.value);
	};

	const insertPlaceholder = (text) => {
		const selection = window.getSelection();
		if (!selection.rangeCount) return;
		const range = selection.getRangeAt(0);
		range.deleteContents();
		range.insertNode(document.createTextNode("#" + text + "#"));
		range.collapse(true);
		selection.removeAllRanges();
		selection.addRange(range);
	};

	let options = props.questions?.map((q) => {
		const id = q.questionId;
		return { text: props.allQuestions?.find((q) => q.questionId === id)?.questionText, id: id };
	}) ?? [];

	const defaultOptions = [
		{ text: "İsim Soyisim", id: -1 },
		{ text: "Telefon Numarası", id: -2 },
	];
	const dropdownItems = [...defaultOptions, ...options]?.map((option) => [option.text, () => insertPlaceholder(option.text, option.id)]);

	const ReplaceDropdown = createDropdown("Yerleştir", dropdownItems);

	return (
		<EditorProvider>
			<Editor value={value} onChange={onChange}>
				<Toolbar>
					<BtnBold />
					<BtnItalic />
					<BtnBulletList />
					<BtnLink />
					<BtnUnderline />
					<BtnStrikeThrough />
					{dropdownItems.length > 0 && <ReplaceDropdown key="replace-dropdown" />}
				</Toolbar>
			</Editor>
		</EditorProvider>
	);
};

export default EditorComponent;
