import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import { DataGrid } from "@mui/x-data-grid";
import { trTR } from "@mui/x-data-grid/locales";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	TextField,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";

import { Button, Link } from "@mui/material";
import { HiVideoCamera } from "react-icons/hi";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CloseIcon from "@mui/icons-material/Close";
//import InfoIcon from '@mui/icons-material/Info'
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";

import putAppointmentCancel from "../../../utils/api/Doctor/Appointment/PutAppointmentCancel";
import getDoctorPatientInfo from "../../../utils/api/Doctor/GetDoctorPatientInfo";
// import enabizRequest from "../../../utils/api/Doctor/Appointment/Enabiz/EnabizRequest";
import enabizModelRequest from "../../../utils/api/Doctor/Appointment/Enabiz/EnabizModelRequest";

import { TaniPicker } from "../../common/TaniPicker";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

export const RandevuListesiMenu = inject("store")(
	observer((props) => {
		const [futureRandevuListesi, setFutureRandevuListesi] = useState(null);
		const [passedRandevuListesi, setPassedRandevuListesi] = useState(null);
		const [paymentWaitingAppointmentList, setPaymentWaitingAppointmentList] = useState(null);

		const [tabValue, setTabValue] = useState(0);

		const [enabizTakipDisplay, setEnabizTakipDisplay] = useState("");
		const [enabizFormDisplay, setEnabizFormDisplay] = useState(0);
		const [formName, setFormName] = useState("");
		const [formSurname, setFormSurname] = useState("");
		const [formTC, setFormTC] = useState("");
		const [formBirthDate, setFormBirthDate] = useState(null);
		const [formGender, setFormGender] = useState(0);

		const [appoStartDate, setAppoStartDate] = useState(null);
		const [appoEndDate, setAppoEndDate] = useState(null);
		const [tempDiag, setTempDiag] = useState(null);

		const [diagList, setDiagList] = useState([]);

		const [patientInfo, setPatientInfo] = useState(null);

		useEffect(() => {
			// Update the document title using the browser API
			// console.log("props.passedAppointmentList: ", props.passedAppointmentList)
			if (props.passedAppointmentList !== null) {
				if (props.passedAppointmentList.length > 0) {
					setPassedRandevuListesi(props.passedAppointmentList);
				}
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.passedAppointmentList]);

		useEffect(() => {
			// Update the document title using the browser API
			// console.log("props.futureAppointmentList: ", props.futureAppointmentList)
			if (props.futureAppointmentList !== null) {
				if (props.futureAppointmentList.length > 0) {
					setFutureRandevuListesi(props.futureAppointmentList);
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.futureAppointmentList]);

		useEffect(() => {
			// Update the document title using the browser API
			// console.log("props.paymentWaitingAppointmentList: ", props.paymentWaitingAppointmentList)
			if (props.paymentWaitingAppointmentList !== null) {
				if (props.paymentWaitingAppointmentList.length > 0) {
					setPaymentWaitingAppointmentList(props.paymentWaitingAppointmentList);
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.paymentWaitingAppointmentList]);

		/*const getirHastaListesi = async (start, end) => {
        props.store.setLoadingBarStatus(false)

        props.store.setLoadingBarStatus(true)
        }*/

		const futureRandevuColumns = [
			{ field: "title", headerName: "Randevu Bilgisi", flex: 1 },
			{
				field: "appointmentDate",
				headerName: "Randevu Tarih Saat",
				flex: 1,
				renderCell: (params) => (
					<>{dayjs(new Date(params.value)).format("DD.MM.YYYY HH:mm")}</>
				),
			},
			{
				field: "appointmentId",
				headerName: "",
				flex: 1,
				renderCell: (params) => (
					<>
						{props.store.userType !== 3 && (
							<Button
								variant={"contained"}
								onClick={() => startVideoCall(params.row)}
							>
								<HiVideoCamera style={{ fontSize: "20px", marginRight: "5px" }} />{" "}
								Görüşme Odasına Gir
							</Button>
						)}
						<Button
							variant="contained"
							color="error"
							onClick={() => cancelAppointment(params.value)}
							style={{ marginLeft: "10px" }}
						>
							<CloseIcon style={{ fontSize: "20px", marginRight: "5px" }} /> İptal Et
						</Button>
					</>
				),
			},
		];

		const passedRandevuColumns = [
			{ field: "title", headerName: "Randevu Bilgisi", flex: 1 },
			{
				field: "appointmentDate",
				headerName: "Randevu Tarih Saat",
				flex: 1,
				renderCell: (params) => (
					<>{dayjs(new Date(params.value)).format("DD.MM.YYYY HH:mm")}</>
				),
			},
			{
				field: "appointmentId",
				headerName: "Dosya - E-Nabız",
				flex: 1,
				renderCell: (params) => {
					return (
						<>
							<Button
								variant={"contained"}
								component={Link}
								href={"/panel/hasta/" + params.row.patientId}
							>
								<FolderCopyIcon style={{ fontSize: "20px", marginRight: "5px" }} />{" "}
								Hasta Dosyası
							</Button>
							{params.row.sysTakip ? (
								<Button
									color="warning"
									style={{
										marginLeft: "10px",
										fontSize: "11px",
										height: "40.5px",
									}}
									variant={"contained"}
									onClick={() => setEnabizTakipDisplay(params.row.sysTakip)}
								>
									{params.row.sysTakip}
								</Button>
							) : (
								<Button
									style={{ marginLeft: "10px" }}
									variant={"contained"}
									onClick={() => getPatientInfo(params.row.patientId, params.id)}
								>
									<AddLinkIcon style={{ fontSize: "20px", marginRight: "5px" }} />{" "}
									E-Nabız'a Aktar
								</Button>
							)}
						</>
					);
				},
			},
		];

		const paymentWaitingRandevuColumns = [
			{ field: "title", headerName: "Randevu Bilgisi", flex: 1 },
			{
				field: "appointmentDate",
				headerName: "Randevu Tarih Saat",
				flex: 1,
				renderCell: (params) => (
					<>{dayjs(new Date(params.value)).format("DD.MM.YYYY HH:mm")}</>
				),
			},
			{
				field: "appointmentId",
				headerName: "",
				flex: 1,
				renderCell: (params) => (
					<>
						<Button
							variant={"contained"}
							component={Link}
							href={"/panel/hasta/" + params.row.patientId}
						>
							<FolderCopyIcon style={{ fontSize: "20px", marginRight: "5px" }} />{" "}
							Hasta Dosyası
						</Button>
						<Button
							variant="contained"
							color="error"
							onClick={() => cancelAppointment(params.value)}
							style={{ marginLeft: "10px" }}
						>
							<CloseIcon style={{ fontSize: "20px", marginRight: "5px" }} /> İptal Et
						</Button>
					</>
				),
			},
		];

		const startVideoCall = async (appointment) => {
			// console.log("appointment: ", appointment)
			props.history.push(
				"/panel/gorusme/" + appointment.appointmentId + "/" + appointment.patientId
			);
			/*let startVideoCallResponse = await putForStartVideoCall(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, appointment.appointmentId)
        console.log("startVideoCallResponse: ", startVideoCallResponse)*/
		};

		const cancelAppointment = async (appointmentId) => {
			props.store.setLoadingBarStatus(false);

			let cancelAppointmentResponse = await putAppointmentCancel(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id,
				appointmentId
			);
			if (cancelAppointmentResponse !== null && cancelAppointmentResponse !== undefined) {
				if (cancelAppointmentResponse.status === 200) {
					props.store.setMessage("Randevu başarıyla iptal edildi.", true, "success");
				} else {
					props.store.setMessage(cancelAppointmentResponse.data, true, "error");
				}
			} else {
				props.store.setMessage("Randevu iptal edilirken hata oluştu", true, "error");
			}
			props.store.setLoadingBarStatus(true);
		};

		const onClickGeriDon = () => {
			props.history.push("/panel/ajanda");
		};

		const handleTabChange = (event, newValue) => {
			setTabValue(newValue);
		};

		const onClickNabizModelIntegration = async () => {
			props.store.setLoadingBarStatus(false);

			if (
				!(
					formTC &&
					formBirthDate &&
					formName &&
					formSurname &&
					formGender &&
					diagList.length > 0 &&
					appoStartDate &&
					appoEndDate
				)
			) {
				return props.store.setMessage(
					"Eksik bilgi var. Lütfen tüm alanları doldurunuz.",
					true,
					"error"
				);
			}

			const enabizForm = {
				tckno: formTC,
				birthDate: formBirthDate,
				name: formName,
				surname: formSurname,
				gender: formGender,
				diagnosisList: diagList,
				appointmentStartDate: appoStartDate,
				appointmentEndDate: appoEndDate,
			};

			console.log("enabizForm: ", enabizForm);

			let eNabizResponse = await enabizModelRequest(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id,
				enabizFormDisplay,
				enabizForm
			);

			if (eNabizResponse !== null && eNabizResponse !== undefined) {
				if (eNabizResponse.status === 200) {
					props.store.setMessage("E-Nabız'a aktarıldı", true, "success");
					setFormClose();
					window.location.reload();
				} else if (eNabizResponse.status === 400) {
					props.store.setMessage(eNabizResponse.data.data, true, "error");
				} else {
					props.store.setMessage(
						"Nabız sayfasına yönlendirilirken hata oluştu.",
						true,
						"error"
					);
				}
			} else {
				props.store.setMessage(
					"Nabız sayfasına yönlendirilirken hata oluştu.",
					true,
					"error"
				);
			}

			props.store.setLoadingBarStatus(true);
		};

		const getPatientInfo = async (patientId, appoId) => {
			const getPatientInfoResponse = await getDoctorPatientInfo(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id,
				patientId
			);
			if (getPatientInfoResponse.status === 200) {
				if (getPatientInfoResponse.data !== null) {
					let tempPatient = toJS(getPatientInfoResponse.data);
					setPatientInfo(tempPatient);
					setFormName(tempPatient.name);
					setFormSurname(tempPatient.surname);
					setFormTC(tempPatient.tckno ? tempPatient.tckno.toString() : "");
					setFormBirthDate(
						tempPatient.birthDate ? new Date(tempPatient.birthDate) : null
					);
					setFormGender(tempPatient.gender);
					setEnabizFormDisplay(appoId);
				} else {
					setPatientInfo(null);
					props.store.setMessage("Aktarırken bir hata oluştu.", true, "error");
				}
			}
		};

		const onChangeTC = (event) => {
			let input = event.target.value;
			const regex = /^[0-9\b]+$/; // only numbers

			if (input.length > 11) {
				input = input.slice(0, 11);
			}

			if (regex.test(input)) {
				setFormTC(input);
			}
		};

		const setFormClose = () => {
			setEnabizFormDisplay(0);
			setFormName("");
			setFormSurname("");
			setFormTC("");
			setFormBirthDate(null);
			setFormGender(0);
			setDiagList([]);
			setTempDiag(null);
			setPatientInfo(null);
			setAppoStartDate(null);
			setAppoEndDate(null);
		};

		const addDiagnosis = () => {
			if (diagList.findIndex((diag) => diag.label === tempDiag.label) === -1) {
				let tempDiagList = [...diagList];
				tempDiagList.push(tempDiag);
				setDiagList(tempDiagList);
			} else {
				props.store.setMessage("Eklemek istediğiniz tanı listede ekli.", true, "warning");
			}
			setTempDiag(null);
		};

		const deleteDiagnosis = (index) => {
			let tempDiagList = diagList;
			tempDiagList.splice(index, 1);
			setDiagList([...tempDiagList]);
		};

		return (
			<>
				<Grid container spacing={3}>
					<Grid item xs={8}>
						<Typography color="secondary" variant="h5" style={{ fontWeight: "bold" }}>
							Randevu Listesi
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Button
							color="primary"
							variant="contained"
							style={{ float: "right" }}
							onClick={onClickGeriDon}
						>
							<ArrowLeftIcon /> Geri Dön
						</Button>
					</Grid>
				</Grid>
				<Card style={{ marginTop: "8px", height: "calc(100vh - 200px)" }}>
					<CardContent style={{ height: "calc(100vh - 200px)", padding: "30px" }}>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs
								value={tabValue}
								onChange={handleTabChange}
								aria-label="basic tabs example"
							>
								<Tab label="RANDEVULARIM" />
								<Tab label="GEÇMİŞ RANDEVULARIM" />
								<Tab label="ÖDEME BEKLEYEN RANDEVULARIM" />
							</Tabs>
						</Box>
						<TabPanel value={tabValue} index={0}>
							<Box sx={{ height: "calc(100vh - 320px)" }}>
								<DataGrid
									rows={futureRandevuListesi !== null ? futureRandevuListesi : []}
									columns={futureRandevuColumns}
									pageSize={10}
									disableSelectionOnClick
									localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
									color="primary"
									style={{ border: "0" }}
									getRowId={(row) => row?.appointmentId}
								/>
							</Box>
						</TabPanel>
						<TabPanel value={tabValue} index={1}>
							<Box sx={{ height: "calc(100vh - 320px)" }}>
								<DataGrid
									rows={passedRandevuListesi !== null ? passedRandevuListesi : []}
									columns={passedRandevuColumns}
									pageSize={10}
									disableSelectionOnClick
									localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
									color="primary"
									style={{ border: "0" }}
									getRowId={(row) => row?.appointmentId}
								/>
							</Box>
						</TabPanel>
						<TabPanel value={tabValue} index={2}>
							<Box sx={{ height: "calc(100vh - 320px)" }}>
								<DataGrid
									rows={
										paymentWaitingAppointmentList !== null
											? paymentWaitingAppointmentList
											: []
									}
									columns={paymentWaitingRandevuColumns}
									pageSize={10}
									disableSelectionOnClick
									localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
									color="primary"
									style={{ border: "0" }}
									getRowId={(row) => row?.appointmentId}
								/>
							</Box>
						</TabPanel>
					</CardContent>
				</Card>
				{patientInfo && (
					<Dialog open={enabizFormDisplay} onClose={setFormClose}>
						<DialogTitle>E-Nabız'a Bilgi Aktar</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Eğer hasta bilgileri eksikse bu formdan bilgileri doldurarak
								E-Nabız'a aktarabilirsiniz.
							</DialogContentText>

							<Grid container spacing={2} style={{ marginTop: "5px", width: "100%" }}>
								<Grid item xs={12}>
									<FormControl fullWidth>
										<TextField
											label="Hasta TC Kimlik Numarası"
											value={formTC}
											onChange={onChangeTC}
											error={formTC.length !== 11}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<TextField
											label="Hasta İsmi"
											value={formName}
											onChange={(event) => setFormName(event.target.value)}
											error={!formName}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<TextField
											label="Hasta Soyismi"
											value={formSurname}
											onChange={(event) => setFormSurname(event.target.value)}
											error={!formSurname}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
											adapterLocale={"tr"}
											style={{ width: "calc(100% - 100px)" }}
										>
											<DatePicker
												label="Hasta Doğum Tarihi"
												value={formBirthDate}
												onChange={(newValue) =>
													setFormBirthDate(new Date(newValue))
												}
												renderInput={(params) => <TextField {...params} />}
												resizable
												maxDate={new Date()}
												error={!formBirthDate}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<InputLabel id="demo-simple-select-label">
											Cinsiyet
										</InputLabel>
										<Select
											id="demo-simple-select"
											labelId="demo-simple-select-label"
											value={formGender}
											label="Cinsiyet"
											onChange={(event) => setFormGender(event.target.value)}
											variant="outlined"
											error={formGender === 0}
										>
											<MenuItem value={0}>Cinsiyet seçiniz</MenuItem>
											<MenuItem value={1}>Erkek</MenuItem>
											<MenuItem value={2}>Kadın</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container spacing={2} style={{ marginTop: "5px", width: "100%" }}>
								<Grid item xs={6}>
									<TaniPicker
										diagnosisId={tempDiag?.label}
										hideInfo
										onChange={(data) => {
											setTempDiag((tempDiag) => {
												return {
													...tempDiag,
													...data,
												};
											});
										}}
										error={!(diagList?.length > 0)}
									/>
								</Grid>
								<Grid item xs={4}>
									<FormControl fullWidth>
										<InputLabel id="demo-simple-select-label">Tanı</InputLabel>
										<Select
											id="demo-simple-select"
											labelId="demo-simple-select-label"
											value={tempDiag?.type || 0}
											label="Cinsiyet"
											onChange={(event) =>
												setTempDiag((tempDiag) => {
													return {
														...tempDiag,
														type: event.target.value,
													};
												})
											}
											variant="outlined"
											error={!tempDiag?.type}
											disabled={!tempDiag}
										>
											<MenuItem value={0}>Tanı seçiniz</MenuItem>
											<MenuItem value={3}>Ön Tanı</MenuItem>
											<MenuItem value={1}>Kesin Tanı</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={2}>
									<Button onClick={addDiagnosis} disabled={!tempDiag?.type}>
										Ekle
									</Button>
								</Grid>
							</Grid>
							{diagList && (
								<Grid
									container
									spacing={2}
									style={{ marginTop: "5px", width: "100%" }}
								>
									{diagList.map((diag, index) => {
										return (
											<Grid
												item
												xs={12}
												key={index}
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													marginTop: "5px",
												}}
											>
												<b>{`${diag.label} - ${
													diag.type === 1 ? "Kesin Tanı" : "Ön Tanı"
												}`}</b>
												<Button
													style={{
														marginInlineStart: "auto",
													}}
													variant="contained"
													onClick={() => deleteDiagnosis(index)}
												>
													Sil
												</Button>
											</Grid>
										);
									})}
								</Grid>
							)}
							<Grid container spacing={2} style={{ marginTop: "5px", width: "100%" }}>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
											adapterLocale={"tr"}
											style={{ width: "calc(100% - 100px)" }}
										>
											<DateTimePicker
												label="Muayene Başlangıç Tarihi"
												value={appoStartDate}
												onChange={(newValue) => {
													setAppoStartDate(new Date(newValue));
													setAppoEndDate(
														new Date(
															new Date(newValue).getTime() +
																15 * 60000
														)
													);
												}}
												renderInput={(params) => <TextField {...params} />}
												resizable
												maxDate={new Date()}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
											adapterLocale={"tr"}
											style={{ width: "calc(100% - 100px)" }}
										>
											<DateTimePicker
												label="Muayene Bitiş Tarihi"
												value={appoEndDate}
												onChange={(newValue) =>
													setAppoEndDate(new Date(newValue))
												}
												renderInput={(params) => <TextField {...params} />}
												resizable
												minDate={appoStartDate}
												maxDate={appoStartDate}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setFormClose()}>Kapat</Button>
							<Button onClick={onClickNabizModelIntegration} variant="contained">
								Aktar
							</Button>
						</DialogActions>
					</Dialog>
				)}

				{enabizTakipDisplay && (
					<Dialog open={enabizTakipDisplay} onClose={() => setEnabizTakipDisplay("")}>
						<DialogTitle>Randevu Takip Numarası</DialogTitle>
						<DialogContent>
							<DialogContentText>{enabizTakipDisplay}</DialogContentText>
						</DialogContent>
					</Dialog>
				)}
			</>
		);
	})
);
